import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"

// import app components
import Layout from "../../components/Layout"
import ComponentToPrint from "../../components/ComponentToPrint"

const Template = (props) => {
  const [storage, setStorage] = useState(null)
  const componentRef = useRef()

  useEffect(() => {
    setStorage(componentRef.current)
  }, [])
  const {
    data: {
      wpProduct: {
        seo,
        acf: { section1, section2 },
      },
    },
  } = props

  return (
    <Layout {...props} seo={seo}>
      <ComponentToPrint
        section1={section1}
        section2={section2}
        printRef={storage}
        ref={componentRef}
      />
    </Layout>
  )
}

export const CollectionQuery = graphql`
  query ProductDefault($id: String!) {
    wpProduct(id: { eq: $id }) {
      title
      slug
      uri
      productCat {
        nodes {
          databaseId
          name
          uri
        }
      }
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      acf {
        fieldGroupName
        section1 {
          fieldGroupName
          text
          tablerow {
            fieldGroupName
            col1
            col2
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
        section2 {
          fieldGroupName
          backgroundcolor
          tables {
            fieldGroupName
            tableheading
            tablerows {
              fieldGroupName
              col1
              col2
            }
          }
        }
      }
    }
  }
`

export default Template
