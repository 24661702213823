import React from "react"
import { Box, Container, Heading, Text } from "theme-ui"
import ReactToPrint from "react-to-print"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Parser from "html-react-parser"

// import app components
import Button from "./Button"
import Edges from "./Edges"
import Textarea from "./Textarea"
import BackLogo from "../icons/textImage-bg.svg"

class ComponentToPrint extends React.Component {
  render() {
    const { section1, section2, printRef } = this.props

    const processedImage = section1?.image?.localFile && getImage(section1.image.localFile)

    return (
      <Container sx={{ bg: "bgWhite" }}>
        {/* Section 1
        -----------------------------------------------------------------------------------------------
        */}
        <Box
          className="pagebreak"
          sx={{
            minHeight: [null, null, `calc(100vh - 160px)`],
            bg: "bgBlue",
            position: "relative",
            display: ["flex", "flex", "grid"],
            gap: 0,
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr",
            flexWrap: "wrap-reverse",
            alignItems: "center",
            "p:last-child": {
              mb: [16, 18, 24],
            },
          }}
        >
          <Box
            sx={{
              right: 0,
              position: "absolute",
              bottom: 0,
              zIndex: 1,
              mixBlendMode: "soft-light",
              opacity: [0.3, 0.5],
              svg: {
                height: "80%",
                width: "650px",
              },
            }}
          >
            <BackLogo />
          </Box>
          <Box
            sx={{
              bg: "white",
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 1,
              gridRowEnd: 2,
              position: "relative",
              height: [300, "100%"],
              width: "100%",
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              order: 1,
              "@media (min-width:52em)": {
                clipPath: "polygon(0 0, 100% 0%, 90% 100%, 0% 100%)",
              },
              ".gatsby-image-wrapper": { bg: "white" },
            }}
          >
            {section1?.image && (
              <GatsbyImage objectFit={`contain`} image={processedImage} />
            )}
          </Box>
          <Box
            sx={{
              gridColumnStart: 2,
              gridColumnEnd: 3,
              gridRowStart: 1,
              gridRowEnd: 2,
              width: "100%",
              height: "100%",
              px: ["null", "80px"],
              "@media (min-width: 1100px)": {
                pl: ["null", "80px"],
                pr: ["null", "120px"],
              },
              pt: "50px",
              pb: 40,
              zIndex: 2,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              "div > *": {
                color: `${
                  section1?.backgroundcolor === "white" ? "text" : "white"
                }`,
              },
              p: {
                pt: 1,
              },
            }}
          >
            <Edges size="md">
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  py: 40,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  maxWidth: "700px",
                }}
              >
                {section1?.text && <Textarea content={section1?.text} />}
                <Box
                  sx={{
                    borderRadius: "10px",
                    bg: "darkBlue",
                    width: ["250px", "270px"],
                    mb: "30px",
                    table: {
                      width: "100%",
                    },
                    "td, th": {
                      border: (theme) => `1px solid ${theme.colors.bgBlue}`,
                      textAlign: "left",
                      width: "50%",
                      pl: 3,
                      py: 1,
                    },
                    "td:last-child": {
                      textAlign: "right",
                      pr: 3,
                      bg: "darkBlue",
                    },
                    "tr:last-child td:last-child": {
                      borderRadius: "0 0 10px 0",
                    },
                    "tr:first-child td:last-child ": {
                      borderRadius: "0 10px 0 0",
                    },
                  }}
                >
                  <table>
                    {section1?.tablerow &&
                      section1?.tablerow.length > 0 &&
                      section1?.tablerow.map((o, i) => (
                        <tr key={i}>
                          <td>
                            {o.col1 && (
                              <Text
                                variant="text.productSingleTable"
                                sx={{
                                  display: "inline-block",
                                  color: "white",
                                }}
                              >
                                {Parser(o.col1)}
                              </Text>
                            )}
                          </td>
                          <td>
                            {o.col2 && (
                              <Text
                                variant="text.productSingleTableText"
                                color="white"
                                children={Parser(o.col2)}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                  </table>
                </Box>
                <ReactToPrint
                  trigger={() => (
                    <Button className="hideInPrint" variant="primary" mt={3}>
                      DOWNLOAD ALL SPECS
                    </Button>
                  )}
                  pageStyle=" @page {
                    size: A4;
                  }
                
                  @media all {
                    .pagebreak {
                      page-break-after: always;
                    }
                  }
                
                  @media print {
                    display: block;
                    .hideInPrint { visibility: hidden };
                    .print:last-child {
                      page-break-after: auto;
                  }
                  } 
                  "
                  content={() => printRef}
                />
              </Box>
            </Edges>
          </Box>
        </Box>
        {/*
      Section 2 -----------------------------------------------------------------------------------------------
      */}
        <Box
          sx={{
            bg: `${
              section2?.backgroundcolor === "white" ? "bgWhite" : "blueModuleBg"
            }`,
          }}
        >
          <Edges size="cmd">
            <Box
              sx={{
                width: "100%",
                pb: "150px",
                table: {
                  width: "100%",
                  border: (theme) => `.5px solid ${theme.colors.textLight}`,
                  borderRadius: "10px",
                  opacity: `${section2?.backgroundcolor === "white" ? 1 : 0.8}`,
                  bg: "white",
                },
                "td, th": {
                  textAlign: "left",
                  width: "50%",
                  pl: 4,
                  py: "10px",
                  border: "none",
                  borderBottom: ".5px solid",
                  borderColor: `${
                    section2?.backgroundcolor === "white"
                      ? "textLight"
                      : "blueModuleBg"
                  }`,
                },
                "td:last-child": {
                  textAlign: "right",
                  pr: 4,
                },
                "tr:last-child td": {
                  border: "none",
                },
              }}
            >
              {section2?.tables &&
                section2?.tables.map((o, i) => (
                  <Box key={i}>
                    {o?.tableheading && (
                      <Heading
                        variant="text.singleProductHeading"
                        sx={{
                          pt: "70px",
                          pb: "30px",
                          color: `${
                            section2?.backgroundcolor === "white"
                              ? "black"
                              : "white"
                          }`,
                        }}
                      >
                        {Parser(o.tableheading)}
                      </Heading>
                    )}

                    <table>
                      <tbody>
                        {o?.tablerows &&
                          o?.tablerows.length > 0 &&
                          o?.tablerows.map((t, idx) => (
                            <tr key={idx}>
                              <td>
                                {t.col1 && (
                                  <Text variant="text.productSingleTableSecond">
                                    {Parser(t.col1)}
                                  </Text>
                                )}
                              </td>

                              <td>
                                {t.col2 && (
                                  <Text variant="text.productSingleText">
                                    {Parser(t.col2)}
                                  </Text>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Box>
                ))}
            </Box>
          </Edges>
        </Box>
      </Container>
    )
  }
}

export default ComponentToPrint
